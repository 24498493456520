<template>
  <form @submit.prevent="save" class="d-flex flex-column gap-2">
    <Field
      :label="$t('updateOrganizationInfo.form.name.label')"
      v-model="model.name"
      :error="errors.name"
      required
    />
    <div class="btn-center">
      <button
        class="d-flex align-items-center gap-2 btn btn-primary btn-sm"
        type="submit"
        :disabled="loading"
      >
        <span v-if="loading" class="spinner-border-sm spinner-border"></span>
        <span>
          {{ $t('updateOrganizationInfo.form.save') }}
        </span>
      </button>
    </div>
  </form>
</template>

<script>
import OrganizationService from '../../../../services/organization.service'
import Field from '../../../../elements/Field.vue'
import { mapActions, mapGetters } from 'vuex'
import { object, string } from 'yup'
import { yupToKV } from '../../../../utils/yup'

export default {
  components: {
    Field
  },
  data() {
    return {
      model: {},
      errors: {},
      loading: false
    }
  },
  computed: {
    ...mapGetters(['currentTenant']),
    schema() {
      return object().shape({
        name: string()
          .trim()
          .min(
            2,
            this.$t('updateOrganizationInfo.form.errors.length.nameMin', {
              num: 2
            })
          )
          .max(
            250,
            this.$t('updateOrganizationInfo.form.errors.length.nameMax', {
              num: 250
            })
          )
      })
    }
  },
  methods: {
    ...mapActions(['setCurrentTenant']),
    async save() {
      try {
        this.errors = {}
        this.loading = true

        await this.schema
          .validate(this.model, { abortEarly: false })
          .catch(err => {
            this.errors = yupToKV(err)
          })

        if (!this.schema.isValidSync(this.model)) {
          return
        }

        await OrganizationService.updateOrganizationInfo(this.model)
        this.setCurrentTenant({
          ...this.currentTenant,
          ...this.model
        })
        this.toast(
          this.$t(
            'updateOrganizationInfo.form.toasts.organization-info-updated'
          )
        )
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    currentTenant: {
      handler(currentTenant) {
        this.model = { ...currentTenant }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.card {
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 1rem;
}

.gap-2 {
  gap: 0.5rem;
}
.btn-center {
  display: grid;
  place-items: center;
}
</style>
